* {
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
}

h1 {
  font-size: 3.5em;
  font-weight: bold;
}

h2 {
  font-size: 1.5em;
  font-weight: normal;
}

p1 {
  font-size: 1em;
  font-weight: normal;
}
